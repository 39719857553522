import Typography from "../../../../components/Typography";
import { Link } from "react-router-dom";
import { formatDistance } from "date-fns";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import { useLang } from "../../../../hooks/useLang";
import Badge from "../../../../components/Badge";

export const rows = (data, deleteGame) => {
  const { language } = useLang();

  return data.map(item => ({
    id: <Typography component={Link} to={`/games/${item.id}`} variant="caption" color="text" fontWeight="medium">{ item.id }</Typography>,
    name: <Typography component={Link} to={`/games/${item.id}`} variant="caption" color="text" fontWeight="medium" >
      {item.translations[Object.keys(item.translations).find((key) => item.translations[key].lang === language)]?.name}
    </Typography>,
    translations: item.translations.map(translation => (
        <Badge key={translation.id} badgeContent={translation.questions.length} size={"xs"} max={99}>
          <img
            style={{ marginLeft: 15 }}
            key={translation.id}
            src={require(`../../../../assets/images/icons/flags/${translation.lang}.png`)}
            width="50"
            height="30"
            alt=""
          />
        </Badge>
    )),
    createdAt: <Typography component={Link} to={`/games/${item.id}`} variant="caption" color="text" fontWeight="medium">{ formatDistance(new Date(item.createdAt), new Date(), { addSuffix: true }) }</Typography>,
    updatedAt: <Typography component={Link} to={`/games/${item.id}`} variant="caption" color="text" fontWeight="medium">{ formatDistance(new Date(item.updatedAt), new Date(), { addSuffix: true }) }</Typography>,
    edit: (
      <IconButton component={Link} to={`/games/${item.id}`} color="info" size="small">
        <Icon>edit</Icon>
      </IconButton>
    ),
    delete: (
      <IconButton color="error" size="small" onClick={() => deleteGame(item.id)}>
        <Icon>delete</Icon>
      </IconButton>
    ),
  }))
}