import DashboardLayout from "../../ui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../ui/Navbars/DashboardNavbar";
import Box from "../../components/Box";
import { Grid } from "@mui/material";
import Footer from "../../ui/Footer";
import { useQuestion } from "../../hooks/questions/useQuestion";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getQuestionById } from "../../slices/questions";
import QuestionsEditForm from "./components/forms/QuestionsEditForm";

const EditQuestions = () => {
  const dispatch = useDispatch();
  const { questionId } = useParams();
  const { question, updateQuestion } = useQuestion();

  useEffect(() => {
    dispatch(getQuestionById(questionId));
  }, []);

  if (!question) {
    return <Loader />
  }

  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <Box mt={ 8 } pb={3}>
        <Grid container spacing={ 3 }>
          <Grid item xs={ 12 }>
            <QuestionsEditForm question={question} updateQuestion={updateQuestion}/>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </DashboardLayout>
  );
};

export default EditQuestions;