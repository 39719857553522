import Practice from "../answers/Practice";

export const answersDefault = {
  fr: {
    right: '§ \nVous avez raison !',
    wrong: '§ \nVous n\'avez pas raison !'
  },
  en: {
    right: '§ \nYou are right!',
    wrong: '§ \nYou are not right!'
  }
}

export const answerRick = {
  fr: {
    Threat: 'Menace',
    Opportunity: 'Opportunité'
  },
  en: {
    Threat: 'Threat',
    Opportunity: 'Opportunity'
  }
}

export const answerPractice = {
  fr: {
    "Bad practice": 'Écart à éviter',
    "Good practice": 'Bonne pratique'
  },
  en: {
    "Bad practice": 'Bad practice',
    "Good practice": 'Good practice'
  }
}