import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

const initialLangState = {
  language: 'fr',
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { language } = action.payload;

    return {
      language
    };
  },
  SET_LANG: (state, action) => {
    const { language } = action.payload;

    return {
      language
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const LanguageContext = createContext({
  ...initialLangState,
  setLang: () => Promise.resolve(),
});

export const LanguageProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialLangState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const language = window.localStorage.getItem('language');

        if (language) {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              language,
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              language: 'fr',
            }
          });
        }
      } catch (err) {
        console.error(err);
      }
    };

    initialize();
  }, []);

  const setLang = async (language) => {
    localStorage.setItem('language', language);

    dispatch({
      type: 'SET_LANG',
      payload: {
        language,
      }
    });
  };

  return (
    <LanguageContext.Provider
      value={{
        ...state,
        setLang,
      }}
    >
      { children }
    </LanguageContext.Provider>
  );
};

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired
};