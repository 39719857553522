import Card from "@mui/material/Card";
import Box from "../../../components/Box";
import Grid from "@mui/material/Grid";
import Input from "../../../components/Input";
import { useDispatch, useSelector } from "react-redux";
import { selectQuestionsInfo, setFilters } from "../../../slices/questions";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "../../../components/Typography";
import { categoriesAutocomplete, categoriesNameById } from "./сategories";

const FilterPanel = () => {
  const dispatch = useDispatch();
  const { filters } = useSelector(selectQuestionsInfo)
  const handleSearch = (value) => {
    dispatch(setFilters({ ...filters, search: value }));
  }

  const handleSort = (sort) => {
    dispatch(setFilters({ ...filters, sort }));
  }

  return (
    <Card>
        <Box pt={ 4 } pb={ 3 } px={ 3 }>
          <Grid container spacing={ 3 }>
            <Grid item xs={ 12 } md={ 4 }>
              <Input
                label="Chercher"
                fullWidth
                value={filters.search}
                onChange={({ target }) => handleSearch(target.value)}
              />
            </Grid>
            <Grid item xs={ 12 } md={ 4 }>
              <Box display="flex" alignItems="center">
                <Typography variant="caption" color="secondary">
                  Trier&nbsp;&nbsp;
                </Typography>
                <Autocomplete
                  disableClearable
                  value={categoriesNameById[filters.sort] ?? 'Tous'}
                  options={[{ label: 'Tous', id: null }, ...categoriesAutocomplete]}
                  onChange={(event, value) => handleSort(value.id)}
                  size="medium"
                  fullWidth
                  renderInput={(params) => <Input {...params} />}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
    </Card>
  );
}

export default FilterPanel;