import {useDispatch, useSelector} from 'react-redux';

import {
  deleteGames,
  loadGames,
  selectAllGames,
  selectGamesInfo
} from "../../slices/games";
import { useCallback, useMemo } from "react";
import { useRefMounted } from "../useRefMounted";
import { Zoom } from "@mui/material";
import { useSnackbar } from "notistack";

export const useGames = () => {
  const isMountedRef = useRefMounted();
  const dispatch = useDispatch();
  const games = useSelector(selectAllGames);
  const { status, error, qty, errorMessage } = useSelector(selectGamesInfo);
  const { enqueueSnackbar } = useSnackbar();

  useMemo(() => {
    dispatch(loadGames());
  }, [isMountedRef, dispatch]);

  const notify = useCallback((message, type) => {
    enqueueSnackbar(message, {
      variant: type,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center'
      },
      TransitionComponent: Zoom
    });
  }, [enqueueSnackbar]);

  const deleteGame = (id) => {
    dispatch(deleteGames(id)).then(({ payload, error }) => {
      if (payload && payload.status === 201) {
        dispatch(loadGames())
        notify('Supprimé avec succès', 'success');
      } else {
        const response = JSON.parse(error.message);
        response.errors.map( mes => notify(mes, 'warning'))
      }
    });
  };

  return { games, status, error, qty, errorMessage, deleteGame };
}
