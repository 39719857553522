// export const BASE_URL = process.env.BASE_URL || 'http://localhost:3001/api/admin';
export const BASE_URL = 'https://api.pqb.fr/api/admin'

export const URLS = {
  AUTH: {
    LOGIN: `${BASE_URL}/authentication/login`
  },
  USERS: {
    ME: `${BASE_URL}/users/me`
  },
  GAMES: {
    BASE: `${BASE_URL}/games`,
  },
  QUESTIONS: {
    BASE: `${BASE_URL}/questions`,
  }
}
