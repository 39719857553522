import DashboardLayout from "../../ui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../ui/Navbars/DashboardNavbar";
import Box from "../../components/Box";
import { Grid } from "@mui/material";
import Footer from "../../ui/Footer";
import QuestionsCreateForm from "./components/forms/QuestionsCreateForm";
import { useGame } from "../../hooks/games/useGame";
import Loader from "../../components/Loader";

const CreateQuestions = () => {
  const { game } = useGame();

  if (!game) {
    return <Loader />
  }

  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini/>
      <Box mt={ 8 } pb={3}>
        <Grid container spacing={ 3 }>
          <Grid item xs={ 12 }>
            <QuestionsCreateForm game={game}/>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </DashboardLayout>
  );
};

export default CreateQuestions;