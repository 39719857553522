import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import { URLS } from "../../config";

const initialAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  token: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, token } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      token
    };
  },
  LOGIN: (state, action) => {
    const { user, token } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      token
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    token: null
  }),
  REGISTER: (state, action) => {
    const { user, token } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      token
    };
  }
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      const token = window.localStorage.getItem('token');

      if (token) {
        const user = (await axios.get(URLS.USERS.ME, { headers: { Authorization: `Bearer ${ token }` } })).data;

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user,
            token
          }
        });
      } else {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            token: null
          }
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null,
          token: null
        }
      });
    }
  };

  const login = async (email, password) => {
    const response = (await axios.post(URLS.AUTH.LOGIN, {
      password,
      email
    })).data;

    const user = { id: response.id, email: response.email }

    localStorage.setItem('token', response.token);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        token: response.token
      }
    });
  };

  const logout = async () => {
    localStorage.removeItem('token');
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (data) => {
    const response = (await axios.post(URLS.AUTH.REGISTER, data)).data;
    const user = { id: response.id, email: response.email, roles: response.roles }

    localStorage.setItem('token', response.token);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
        token: response.token
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        register
      }}
    >
      { children }
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const AuthConsumer = AuthContext.Consumer;
