import { useEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";
import routes from "routes";
import { setMiniSidenav, useMaterialUIController } from "context";
import SignIn from "./pages/authentication/sign-in";
import { AuthConsumer } from "./context/auth/JWTAuthProvider";
import Loader from "./components/Loader";
import { SnackbarProvider } from "notistack";
import Sidenav from "./ui/Sidenav";
import { Authenticated } from "./context/auth/Authenticated";

export default function App() {
  const [controller] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={ route.route } element={ route.component } key={ route.key } />;
      }

      return null;
    });

  return (
    <SnackbarProvider
      maxSnack={ 6 }
      anchorOrigin={ {
        vertical: "bottom",
        horizontal: "right"
      } }
    >
      <ThemeProvider theme={ theme }>
        <CssBaseline />
        <AuthConsumer>
          { (auth) =>
            !auth.isInitialized ? (
              <Loader />
            ) : !auth.isAuthenticated && pathname !== "/authentication/sign-in" && (
              <Navigate to="/authentication/sign-in" />
            )
          }
        </AuthConsumer>
        {layout === "dashboard" && (
         <>
            <Sidenav
              color={sidenavColor}
              brandName="PQB JEU"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          </>
        )}
        <Authenticated>
          <Routes>
            { getRoutes(routes) }
            <Route path="*" element={ <Navigate to="/games" /> } />
            <Route exact path="/authentication/sign-in" element={ <SignIn /> } key="sign-in" />
          </Routes>
        </Authenticated>
      </ThemeProvider>
    </SnackbarProvider>
  );
}
