import DashboardLayout from "../../ui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../ui/Navbars/DashboardNavbar";
import Box from "../../components/Box";
import { Grid } from "@mui/material";
import Footer from "../../ui/Footer";
import Loader from "../../components/Loader";
import { useGame } from "../../hooks/games/useGame";
import GamesEditForm from "./components/forms/GamesEditForm";
import { QuestionsDataTable } from "../questions/components/table/QuestionsDataTable";
import FilterPanel from "../questions/components/FilterPanel";

const EditGames = () => {
  const { game, createGame, updateGame } = useGame();

  if (!game) {
    return <Loader />
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box mt={ 8 } pb={3}>
        <Grid container spacing={ 3 }>
          <Grid item xs={ 12 }>
            <GamesEditForm game={game} createGame={createGame} updateGame={updateGame} />
          </Grid>
          <Grid item xs={ 12 }>
            <FilterPanel />
          </Grid>
          <Grid item xs={ 12 }>
            { game && game.translations && (<QuestionsDataTable game={game}/>) }
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </DashboardLayout>
  );
};

export default EditGames;