import Box from "../../../../components/Box";
import Grid from "@mui/material/Grid";
import { Checkbox, FormGroup } from "@mui/material";
import Input from "../../../../components/Input";

const QCM = ({ values, setFieldValue }) => {
  const rows = [...values];
  const maxLength = 6;
  const count = maxLength - rows.length;

  if (values.length < maxLength) {
    for (let index = 0; index < count; index++) {
      rows.push({ title: '', right: false })
    }
  }

  return (
    <Box pt={ 4 } pb={ 3 } px={ 3 }>
      <Grid container spacing={ 3 }>
        <FormGroup
          onChange={({ target: { name, type, checked, value }}) => setFieldValue(name, type === 'text' ? value : checked)}
        >
          {rows.map((answer, index) => (
            <Grid key={index} container spacing={ 3 } pb={ 2 }>
              <Grid item xs={ 12 } md={ 10 } >
                <Input name={`answers[${index}].title`} value={answer.title} label="Name" fullWidth/>
              </Grid>
              <Grid item xs={ 12 } md={ 2 } >
                <Checkbox name={`answers[${index}].right`} checked={answer.right}/>
              </Grid>
            </Grid>
          ))}
        </FormGroup>
      </Grid>
    </Box>
  )
}

export default QCM;