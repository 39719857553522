import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";

import { MaterialUIControllerProvider } from "context";
import { Provider } from "react-redux";
import { store } from "./store";
import { AuthProvider } from "./context/auth/JWTAuthProvider";
import { LanguageProvider } from "./context/language/LanguageProvider";

ReactDOM.render(
  <Provider store={ store }>
    <AuthProvider>
      <LanguageProvider >
        <BrowserRouter>
          <MaterialUIControllerProvider>
            <App />
          </MaterialUIControllerProvider>
        </BrowserRouter>
      </LanguageProvider>
    </AuthProvider>
  </Provider>,
  document.getElementById("root")
);
