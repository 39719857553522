export const categories = [
  {
    name: 'Risque',
    value: 1
  },
  {
    name: 'Pratique',
    value: 2
  },
  {
    name: 'Cas',
    value: 3
  },
  {
    name: 'QCM',
    value: 4
  },
]
export const categoriesAutocomplete = [
  {
    label: 'Risque',
    id: 1
  },
  {
    label: 'Pratique',
    id: 2
  },
  {
    label: 'Cas',
    id: 3
  },
  {
    label: 'QCM',
    id: 4
  },
]

export const categoriesNameById = {
  1: 'Risque',
  2: 'Pratique',
  3: 'Cas',
  4: 'QCM',
}