import Typography from "../../../../components/Typography";
import { categoriesNameById } from "../сategories";
import { Rating } from "@mui/material";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import { Grade, GradeOutlined } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

export const rows = (id, data, deleteQuestion) => {
  const customIcons = {
    1: {
      icon: <Grade color="info" />,
    },
    2: {
      icon: <GradeOutlined color="secondary" />,
    },
    3: {
      icon: <Grade color="error" />,
    },
  };

  function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
  }

  return data.map(item => ({
    title: <Typography component={Link} to={`/games/${id}/questions/${item.id}`} variant="caption" color="text" fontWeight="medium">{ item.title }</Typography>,
    category: <Typography component={Link} to={`/games/${id}/questions/${item.id}`} variant="caption" color="text" fontWeight="medium">{ categoriesNameById[item.category] }</Typography>,
    rating:
      <Typography component="span" variant="caption" color="text" fontWeight="medium">
        <StyledRating
          readOnly
          size="small"
          min={1}
          max={3}
          highlightSelectedOnly
          IconContainerComponent={IconContainer}
          value={ item.rating }
        />
      </Typography>,
    edit: (
      <IconButton component={Link} to={`/games/${id}/questions/${item.id}`} color="info" size="small">
        <Icon>edit</Icon>
      </IconButton>
    ),
    delete: (
      <IconButton color="error" size="small" onClick={() => deleteQuestion(item.id)}>
        <Icon>delete</Icon>
      </IconButton>
    ),
  }))
}