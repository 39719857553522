import {useDispatch, useSelector} from 'react-redux';

import {
  createQuestions,
  updateQuestions,
  deleteQuestions,
  selectQuestionsInfo,
  selectQuestion, getQuestionById
} from "../../slices/questions";
import { useCallback, useMemo } from "react";
import { Zoom } from "@mui/material";
import { useSnackbar } from "notistack";
import { useLang } from "../useLang";
import { useNavigate, useParams } from "react-router-dom";
import { useRefMounted } from "../useRefMounted";
import { selectGame } from "../../slices/games";
import { useAuth } from "../use-auth";

export const useQuestion = () => {
  const isMountedRef = useRefMounted();
  const { logout } = useAuth();
  const { questionId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const game = useSelector(selectGame);
  const question = useSelector(selectQuestion);
  const { status, error, errorMessage } = useSelector(selectQuestionsInfo);
  const { enqueueSnackbar } = useSnackbar();
  const { language } = useLang();

  useMemo(() => {
    if (questionId) {
      dispatch(getQuestionById(questionId));
    }
  }, [questionId, isMountedRef, language]);

  const notify = useCallback((message, type) => {
    enqueueSnackbar(message, {
      variant: type,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center'
      },
      TransitionComponent: Zoom
    });
  }, [enqueueSnackbar]);

  const createQuestion = (data) => {
    dispatch(createQuestions(data)).then(({ payload , error }) => {
      if (payload && payload.status === 201) {
        notify('Créé avec succès', 'success');
        navigate(`/games/${game.id}`)
      } else {
        const response = JSON.parse(error.message);
        if(response.statusCode === 401) {
          logout().then();
        }
        response.errors.map( mes => notify(mes, 'warning'))
      }
    })
  };

  const updateQuestion = (data) => {
    dispatch(updateQuestions({ id: questionId, data })).then(({ payload, error }) => {
      if (payload && payload.status === 201) {
        notify('Mise à jour réussie', 'success');
        navigate(`/games/${game.id}`)
      } else {
        const response = JSON.parse(error.message);
        if(response.statusCode === 401) {
          logout().then();
        }
        response.errors.map( mes => notify(mes, 'warning'))
      }
    })
  };

  const deleteQuestion = (id) => {
    dispatch(deleteQuestions(id)).then(({ payload, error}) => {
      if (payload && payload.status === 201) {
        notify('Supprimé avec succès', 'success');
        navigate(`/games/${game.id}`)
      } else {
        const response = JSON.parse(error.message);
        if(response.statusCode === 401) {
          logout().then();
        }
        response.errors.map( mes => notify(mes, 'warning'))
      }
    });
  };

  return { question, status, error, errorMessage, createQuestion, updateQuestion, deleteQuestion };
}
