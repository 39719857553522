import axios from "axios";
import { BASE_URL } from "./index";

class Request {
  static async request(method = 'GET', collection, data ) {
    const lang = window.localStorage.language;
    const token = window.localStorage.token;

    const headers = {
      'Content-Type': 'application/json',
      lang
    };

    if (token) {
      headers.Authorization = `Bearer ${token}`
    }

    try {
      return await axios({
        url: `${BASE_URL}/${collection}`,
        method,
        data,
        headers
      });
    } catch (e) {
      return Promise.reject(JSON.stringify(e.response.data));
    }
  }

  static async get(collection) {
    return this.request('GET', collection)
  }

  static async post(collection, data) {
    return this.request('POST', collection, data)
  }

  static async put(collection, data) {
    return this.request('PUT', collection, data)
  }

  static async delete(collection) {
    return this.request('DELETE', collection)
  }
}

export default Request;