import {useDispatch, useSelector} from 'react-redux';

import {
  getGameById,
  createGames,
  updateGames,
  deleteGames,
  selectGamesInfo,
  selectGame,
} from "../../slices/games";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { Zoom } from "@mui/material";
import { useSnackbar } from "notistack";
import { useRefMounted } from "../useRefMounted";
import { useLang } from "../useLang";
import { useAuth } from "../use-auth";

export const useGame = () => {
  const { gameId } = useParams();
  const isMountedRef = useRefMounted();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const game = useSelector(selectGame);
  const { status, error, errorMessage } = useSelector(selectGamesInfo);
  const { enqueueSnackbar } = useSnackbar();
  const { language } = useLang();

  useEffect(() => {
    if (gameId) {
      dispatch(getGameById(gameId));
    }
  }, [isMountedRef, language, dispatch, gameId]);

  const notify = useCallback((message, type) => {
    enqueueSnackbar(message, {
      variant: type,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center'
      },
      TransitionComponent: Zoom
    });
  }, [enqueueSnackbar]);

  const createGame = (data) => {
    dispatch(createGames(data)).then(({ payload, error }) => {
      if (payload && payload.status === 201) {
        notify('Créé avec succès', 'success');
        navigate('/games');
      } else {
        const response = JSON.parse(error.message);
        if(response.statusCode === 401) {
          logout().then();
        }
        response.errors.map( mes => notify(mes, 'warning'))
      }
    })
  };

  const updateGame = (data) => {
    dispatch(updateGames({ id: gameId, data })).then(({ payload, error }) => {
      if (payload && payload.status === 201) {
        notify('Mise à jour réussie', 'success');
        navigate('/games');
      } else {
        const response = JSON.parse(error.message);
        if(response.statusCode === 401) {
          logout().then();
        }
        response.errors.map( mes => notify(mes, 'warning'))
      }
    })
  };

  const deleteGame = (id) => {
    dispatch(deleteGames(id)).then(({ payload, error }) => {
      if (payload && payload.status === 201) {
        notify('Supprimé avec succès', 'success');
        navigate('/games');
      } else {
        const response = JSON.parse(error.message);
        if(response.statusCode === 401) {
          logout().then();
        }
        response.errors.map( mes => notify(mes, 'warning'))
      }
    });
  };

  return { game, status, error, errorMessage, createGame, updateGame, deleteGame };
}
