export const textByCategoryDefault = {
  fr: {
    // Risque
    1: 'Est-ce que l\'affirmation suivante est plutôt une menace ou une opportunité',
    // Pratique
    2: 'Est-ce que l\'affirmation suivante est plutôt une bonne pratique ou un écart à éviter?',
    // Cas
    3: '',
    // QCM
    4: '',
  },
  en: {
    // Risque
    1: 'Is the following statement more of a threat or an opportunity?',
    // Pratique
    2: 'Is the following statement rather a good practice or a deviation to be avoided?',
    // Cas
    3: '',
    // QCM
    4: '',
  }
}