import Card from "@mui/material/Card";
import Box from "components/Box";
import Typography from "components/Typography";
import Input from "components/Input";
import Button from "components/Button";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../../hooks/use-auth";
import { useNavigate } from "react-router-dom";
import BasicLayout from "../components/BasicLayout";

const SignIn = () => {
  const navigate = useNavigate();
  const { login } = useAuth();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      submit: null
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("The email provided should be a valid email address")
        .max(255)
        .required("The email field is required"),
      password: Yup.string()
        .max(255)
        .required("The password field is required")
    }),
    onSubmit: async (values) => {
      try {
        await login(values.email, values.password);
        navigate("/");
      } catch (e) {

      }
    }
  });

  return (
    <BasicLayout image={ bgImage }>
      <Card>
        <Box
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={ 2 }
          mt={ -3 }
          p={ 2 }
          mb={ 1 }
          textAlign="center"
        >
          <Typography variant="h4" fontWeight="medium" color="white" mt={ 1 }>
            Sign in
          </Typography>
        </Box>
        <Box pt={ 4 } pb={ 3 } px={ 3 }>
          <form onSubmit={ formik.handleSubmit }>
            <Box mb={ 2 }>
              <Input
                type="email"
                name="email"
                label="Email"
                fullWidth
                helperText={ formik.touched.email && formik.errors.email }
                error={ Boolean(formik.touched.email && formik.errors.email) }
                onBlur={ formik.handleBlur }
                onChange={ formik.handleChange }
                value={ formik.values.email }
              />
            </Box>
            <Box mb={ 2 }>
              <Input
                type="password"
                label="Password"
                name="password"
                fullWidth
                helperText={ formik.touched.password && formik.errors.password }
                error={ Boolean(formik.touched.password && formik.errors.password) }
                onBlur={ formik.handleBlur }
                onChange={ formik.handleChange }
                value={ formik.values.password }
              />
            </Box>
            <Box mt={ 4 } mb={ 1 }>
              <Button variant="gradient" type="submit" color="info" fullWidth disabled={ formik.isSubmitting }>
                sign in
              </Button>
            </Box>
          </form>
        </Box>
      </Card>
    </BasicLayout>
  );
};

export default SignIn;
