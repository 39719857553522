import DashboardLayout from "../../ui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../ui/Navbars/DashboardNavbar";
import Box from "../../components/Box";
import { Grid } from "@mui/material";
import Footer from "../../ui/Footer";
import GamesCreateForm from "./components/forms/GamesCreateForm";

const CreateGames = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar absolute />
      <Box mt={ 8 } pb={3}>
        <Grid container spacing={ 3 }>
          <Grid item xs={ 12 }>
            <GamesCreateForm />
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </DashboardLayout>
  );
};

export default CreateGames;