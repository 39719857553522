import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const me = createAsyncThunk(
  '@@user/me',
  (_, {
    extra: {client, api},
  }) => {
    return client.get(api.me)
  }
);

export const login = createAsyncThunk(
  '@@user/login',
  (payload, {
    extra: {client, api},
  }) => {
    return client.post(api.signIn, payload)
  }
);

const initialState = {
  status: 'idle',
  error: null,
  user: null,
}

const countrySlice = createSlice({
  name: '@@user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(me.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(me.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.payload || action.meta.error;
      })
      .addCase(me.fulfilled, (state, action) => {
        state.status = 'received';
        state.user = action.payload.data;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = 'received';
        state.user = action.payload.data;
      })
  }
})

export const userReducer = countrySlice.reducer;

export const selectUser = (state) => state.user;
