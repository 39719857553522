import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import Box from "../../../../components/Box";
import Typography from "../../../../components/Typography";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useLang } from "../../../../hooks/useLang";
import IconButton from "@mui/material/IconButton";
import { Zoom } from "@mui/material";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { copyGameById } from "../../../../slices/games";

const GamesEditForm = ({ game, updateGame }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { gameId } = useParams();
  const { language } = useLang();
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      name: game && game.translations && gameId ? game.translations.name : "",
      regulations: game && game.translations && gameId ? game.translations.regulations : "",
      submit: null
    },
    validationSchema: Yup.object({
      name: Yup.string().required('required')
    }),
    onSubmit: async (values) => {
      const data = {
        translations: [
          {
            name: values.name,
            regulations: values.regulations,
            lang: language
          }
        ]
      };

      if (game && game.translations) {
        data.translations[0].id = game.translations.id;
      }

      updateGame(data);
    }
  });

  const copyUuid = async (uuid) => {
    await navigator.clipboard.writeText(uuid);

    enqueueSnackbar('Uiid copied to clipboard!', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center'
      },
      TransitionComponent: Zoom
    });
  }

  const handleCopyGame = () => {
    dispatch(copyGameById(gameId)).then(({ payload, error }) => {
      if (payload && payload.status === 200) {
        enqueueSnackbar('Jeu copié avec succès', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          TransitionComponent: Zoom
        });
        navigate('/games');
      } else {
        const response = JSON.parse(error.message);
        enqueueSnackbar(response, {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          TransitionComponent: Zoom
        });
      }
    });
  };

  return (
    <Card>
      <form onSubmit={ formik.handleSubmit }>
        <Box pt={ 2 } px={ 2 } display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" fontWeight="medium">
            Jeu
          </Typography>
          <Typography onClick={() => copyUuid(game.uuid)} variant="h6" fontWeight="medium">
            <IconButton color="info" size="medium">
              <Icon>copy</Icon>
            </IconButton>
            {game.uuid}
          </Typography>
        </Box>
        <Box pt={ 4 } pb={ 3 } px={ 3 }>
          <Grid container spacing={ 3 }>
            <Grid item xs={ 12 } md={ 4 }>
              <Input
                type="text"
                name="name"
                label="Nom"
                fullWidth
                helperText={ formik.touched.name && formik.errors.name }
                error={ Boolean(formik.touched.name && formik.errors.name) }
                onBlur={ formik.handleBlur }
                onChange={ formik.handleChange }
                value={ formik.values.name }
              />
            </Grid>
            <Grid item xs={ 12 } md={ 4 }>
              <Input
                type="text"
                name="regulations"
                label="Règlements"
                fullWidth
                helperText={ formik.touched.regulations && formik.errors.regulations }
                error={ Boolean(formik.touched.name && formik.errors.regulations) }
                onBlur={ formik.handleBlur }
                onChange={ formik.handleChange }
                value={ formik.values.regulations }
              />
            </Grid>
          </Grid>
        </Box>
        <Box pb={ 2 } px={ 2 } display="flex" justifyContent="right" alignItems="center">
          <Button sx={{ mr: 2 }} variant="gradient" color="info" onClick={() => handleCopyGame()}>
            <Icon sx={ { fontWeight: "bold" } }>copy</Icon>
            &nbsp;Copier le jeu
          </Button>
          <Button type="submit" variant="gradient" color="info" disabled={ formik.isSubmitting }>
            <Icon sx={ { fontWeight: "bold" } }>save</Icon>
            &nbsp;Enregistrer
          </Button>
        </Box>
      </form>
    </Card>
  );
};

export default GamesEditForm;
