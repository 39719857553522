import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const COLLECTION_NAME = 'games';

export const loadGames = createAsyncThunk(
  "@@games/list",
  (filter= null, {
    extra: { client }
  }) => {
    return client.get(`${COLLECTION_NAME}${filter ? `?filter=${JSON.stringify(filter)}` : ''}`);
  }
);

export const getGameById = createAsyncThunk(
  "@@games/get",
  (id, {
    extra: { client }
  }) => {
    return client.get(`${COLLECTION_NAME}/${ id }`);
  }
);

export const createGames = createAsyncThunk(
  "@@games/create",
  (data, {
    extra: { client }
  }) => {
    return client.post(COLLECTION_NAME, data);
  }
);

export const updateGames = createAsyncThunk(
  "@@games/update",
  ({ id, data }, {
    extra: { client }
  }) => {
    return client.put(`${COLLECTION_NAME}/${ id }`, data);
  }
);

export const deleteGames = createAsyncThunk(
  "@@games/delete",
  (id, {
    extra: { client }
  }) => {
    return client.delete(`${COLLECTION_NAME}/${ id }`);
  }
);

export const copyGameById = createAsyncThunk(
  "@@games/copy",
  (id, {
    extra: { client }
  }) => {
    return client.get(`${COLLECTION_NAME}/copy/${ id }`);
  }
);

const initialState = {
  status: "idle",
  error: null,
  errorMessage: null,
  list: [],
  one: null,
};

const gamesSlice = createSlice({
  name: "@@games",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadGames.pending, (state) => {
        state.status = "loading";
        state.error = null;
        state.list = [];
      })
      .addCase(loadGames.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload || action.meta.error;
        state.list = [];
      })
      .addCase(loadGames.fulfilled, (state, action) => {
        state.status = "received";
        state.list = action.payload.data;
      })
      .addCase(getGameById.pending, (state) => {
        state.status = "loading";
        state.error = null;
        state.one = null;
      })
      .addCase(getGameById.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload || action.meta.error;
        state.one = null;
      })
      .addCase(getGameById.fulfilled, (state, action) => {
        state.status = "received";
        state.one = action.payload.data;
      })
      .addCase(createGames.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createGames.rejected, (state, action) => {
        const response = JSON.parse(action.error.message);

        state.status = "rejected";
        state.error = response.error;
        state.errorMessage = response.message;
      })
      .addCase(createGames.fulfilled, (state) => {
        state.status = "received";
      })
      .addCase(updateGames.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateGames.rejected, (state, action) => {
        const response = JSON.parse(action.error.message);

        state.status = "rejected";
        state.error = response.error;
        state.errorMessage = response.message;
      })
      .addCase(updateGames.fulfilled, (state) => {
        state.status = "received";
      })
      .addCase(deleteGames.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteGames.rejected, (state, action) => {
        const response = JSON.parse(action.error.message);

        state.status = "rejected";
        state.error = response.error;
        state.errorMessage = response.message;
      })
      .addCase(deleteGames.fulfilled, (state) => {
        state.status = "received";
      })
      .addCase(copyGameById.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(copyGameById.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload || action.meta.error;
        state.one = null;
      })
      .addCase(copyGameById.fulfilled, (state) => {
        state.status = "received";
      })
  }
});

export const gamesReducer = gamesSlice.reducer;

// Selectors
export const selectAllGames = (state) => state.game.list;
export const selectGame = (state) => state.game.one;

export const selectGamesInfo = (state) => ({
  status: state.game.status,
  error: state.game.error,
  qty: state.game.list.length,
  errorMessage: state.game.errorMessage
});