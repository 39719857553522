import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import Box from "../../../../components/Box";
import Typography from "../../../../components/Typography";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLang } from "../../../../hooks/useLang";
import { useGame } from "../../../../hooks/games/useGame";

const GamesEditForm = () => {
  const { createGame } = useGame();
  const { language } = useLang();

  const formik = useFormik({
    initialValues: {
      name: '',
      regulations: '',
      submit: null
    },
    validationSchema: Yup.object({
      name: Yup.string().required('required')
    }),
    onSubmit: async (values) => {
      const data = {
        translations: [
          {
            name: values.name,
            regulations: values.regulations,
            lang: language
          }
        ]
      };
      createGame(data);
    }
  });

  return (
    <Card id="delete-account">
      <form onSubmit={ formik.handleSubmit }>
        <Box pt={ 2 } px={ 2 } display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" fontWeight="medium">
            Jeu
          </Typography>
        </Box>
        <Box pt={ 4 } pb={ 3 } px={ 3 }>
          <Grid container spacing={ 3 }>
            <Grid item xs={ 12 } md={ 4 }>
              <Input
                type="text"
                name="name"
                label="Nom"
                fullWidth
                helperText={ formik.touched.name && formik.errors.name }
                error={ Boolean(formik.touched.name && formik.errors.name) }
                onBlur={ formik.handleBlur }
                onChange={ formik.handleChange }
                value={ formik.values.name }
              />
            </Grid>
            <Grid item xs={ 12 } md={ 4 }>
              <Input
                type="text"
                name="regulations"
                label="Règlements"
                fullWidth
                helperText={ formik.touched.regulations && formik.errors.regulations }
                error={ Boolean(formik.touched.regulations && formik.errors.regulations) }
                onBlur={ formik.handleBlur }
                onChange={ formik.handleChange }
                value={ formik.values.regulations }
              />
            </Grid>
          </Grid>
        </Box>
        <Box pb={ 2 } px={ 2 } display="flex" justifyContent="right" alignItems="center">
          <Button type="submit" variant="gradient" color="info" disabled={ formik.isSubmitting }>
            <Icon sx={ { fontWeight: "bold" } }>save</Icon>
            &nbsp;Création
          </Button>
        </Box>
      </form>
    </Card>
  );
};

export default GamesEditForm;
