import {configureStore} from '@reduxjs/toolkit';
import Request from "./config/request";
import { userReducer } from "./slices/user/slice";
import { questionReducer } from "./slices/questions";
import { gamesReducer } from "./slices/games";
import { clientsReducer } from "./slices/clients";

export const store = configureStore({
  reducer: {
    user: userReducer,
    question: questionReducer,
    game: gamesReducer,
    client: clientsReducer
  },
  devTools: true,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    thunk: {
      extraArgument: {
        client: Request
      },
    },
    serializableCheck: false,
  })
});
