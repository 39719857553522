import { Icon } from "@mui/material";
import Games from "./pages/games";
import CreateQuestions from "./pages/questions/CreateQuestions";
import EditQuestions from "./pages/questions/EditQuestions";
import CreateGames from "./pages/games/CreateGames";
import EditGames from "./pages/games/EditGames";
import Clients from "./pages/clients";
import Profile from "./pages/clients/profile";

const routes = [
  {
    type: "collapse",
    name: "Jeux",
    key: "games",
    icon: <Icon fontSize="small">games</Icon>,
    route: "/games",
    component: <Games />,
  },
  {
    name: "Créer des jeux",
    key: "createGames",
    route: "/games/create",
    component: <CreateGames />,
  },
  {
    name: "Modifier les jeux",
    key: "editGames",
    route: "/games/:gameId",
    component: <EditGames />,
  },
  {
    name: "Créer des questions",
    key: "createQuestions",
    route: "/games/:gameId/questions/create",
    component: <CreateQuestions />,
  },
  {
    name: "Modifier les questions",
    key: "editQuestions",
    route: "/games/:gameId/questions/:questionId",
    component: <EditQuestions />,
  },
  // {
  //   type: "collapse",
  //   name: "Clients",
  //   key: "clients",
  //   icon: <Icon fontSize="small">group</Icon>,
  //   route: "/clients",
  //   component: <Clients />,
  // },
  // {
  //   name: "Profil client",
  //   key: "clientProfile",
  //   route: "/clients/:clientId",
  //   component: <Profile />,
  // },
];

export default routes;
