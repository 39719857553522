import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from "../../hooks/use-auth";
import axios from "axios";
import { URLS } from "../../config";
import { useNavigate } from "react-router-dom";

export const Authenticated = (props) => {
  const { children } = props;
  const { logout } = useAuth();
  const navigation = useNavigate();
  const token = window.localStorage.getItem('token');

  useEffect(async () => {
    try {
      await axios.get(URLS.USERS.ME, { headers: { Authorization: `Bearer ${ token }` } });
    } catch (e) {
      if (e.response.status === 401) {
        await logout()
      }
    }
  }, [navigation]);


  return children;
};

Authenticated.propTypes = {
  children: PropTypes.node
};
