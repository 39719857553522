import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const COLLECTION_NAME = 'clients';

export const loadClients = createAsyncThunk(
  "@@clients/list",
  (filter= null, {
    extra: { client }
  }) => {
    return client.get(`${COLLECTION_NAME}${filter ? `?filter=${JSON.stringify(filter)}` : ''}`);
  }
);

export const getClientById = createAsyncThunk(
  "@@clients/get",
  (id, {
    extra: { client }
  }) => {
    return client.get(`${COLLECTION_NAME}/${ id }`);
  }
);

export const createClients = createAsyncThunk(
  "@@clients/create",
  (data, {
    extra: { client }
  }) => {
    return client.post(COLLECTION_NAME, data);
  }
);

export const updateClients = createAsyncThunk(
  "@@clients/update",
  ({ id, data }, {
    extra: { client }
  }) => {
    return client.put(`${COLLECTION_NAME}/${ id }`, data);
  }
);

export const deleteClients = createAsyncThunk(
  "@@clients/delete",
  (id, {
    extra: { client }
  }) => {
    return client.delete(`${COLLECTION_NAME}/${ id }`);
  }
);

export const addGame = createAsyncThunk(
  "@@clients/add-game",
  (data, {
    extra: { client }
  }) => {
    return client.put(`${COLLECTION_NAME}/add-game`, data);
  }
);

const initialState = {
  status: "idle",
  error: null,
  errorMessage: null,
  list: [],
  one: null,
};

const clientsSlice = createSlice({
  name: "@@clients",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadClients.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(loadClients.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload || action.meta.error;
      })
      .addCase(loadClients.fulfilled, (state, action) => {
        state.status = "received";
        state.list = action.payload.data;
      })
      .addCase(getClientById.pending, (state) => {
        state.status = "loading";
        state.error = null;
        state.one = null;
      })
      .addCase(getClientById.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload || action.meta.error;
        state.one = null;
      })
      .addCase(getClientById.fulfilled, (state, action) => {
        state.status = "received";
        state.one = action.payload.data;
      })
      .addCase(createClients.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createClients.rejected, (state, action) => {
        const response = JSON.parse(action.error.message);

        state.status = "rejected";
        state.error = response.error;
        state.errorMessage = response.message;
      })
      .addCase(createClients.fulfilled, (state) => {
        state.status = "received";
      })
      .addCase(updateClients.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateClients.rejected, (state, action) => {
        const response = JSON.parse(action.error.message);

        state.status = "rejected";
        state.error = response.error;
        state.errorMessage = response.message;
      })
      .addCase(updateClients.fulfilled, (state) => {
        state.status = "received";
      })
      .addCase(deleteClients.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteClients.rejected, (state, action) => {
        const response = JSON.parse(action.error.message);

        state.status = "rejected";
        state.error = response.error;
        state.errorMessage = response.message;
      })
      .addCase(deleteClients.fulfilled, (state) => {
        state.status = "received";
      })
      .addCase(addGame.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addGame.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload || action.meta.error;
      })
      .addCase(addGame.fulfilled, (state) => {
        state.status = "received";
      });
  }
});

export const clientsReducer = clientsSlice.reducer;

// Selectors
export const selectAllClients = (state) => state.client.list;
export const selectClient = (state) => state.client.one;

export const selectClientsInfo = (state) => ({
  status: state.client.status,
  error: state.client.error,
  qty: state.client.list.length,
  errorMessage: state.client.errorMessage
});