import { useDispatch, useSelector } from "react-redux";

import {
  deleteQuestions,
  loadQuestions,
  selectAllQuestions,
  selectQuestionsInfo
} from "../../slices/questions";
import { useCallback, useMemo } from "react";
import { useRefMounted } from "../useRefMounted";
import { selectGame } from "../../slices/games";
import { Zoom } from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

export const useQuestions = () => {
  const isMountedRef = useRefMounted();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const game = useSelector(selectGame);
  const questions = useSelector(selectAllQuestions);
  const { status, error, qty, errorMessage, filters } = useSelector(selectQuestionsInfo);
  const { enqueueSnackbar } = useSnackbar();

  useMemo(() => {
    if (game) {
      dispatch(loadQuestions({ ...filters, translationId: game.translations.id }));
    }
  }, [isMountedRef, filters]);

  const notify = useCallback((message, type) => {
    enqueueSnackbar(message, {
      variant: type,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center'
      },
      TransitionComponent: Zoom
    });
  }, [enqueueSnackbar]);


  const deleteQuestion = (id) => {
    dispatch(deleteQuestions(id)).then(({ payload, error}) => {
      if (payload && payload.status === 201) {
        notify('Supprimé avec succès', 'success');
        dispatch(loadQuestions({ translationId: game.translations.id }));
        navigate(`/games/${game.id}`)
      } else {
        const response = JSON.parse(error.message);
        response.errors.map( mes => notify(mes, 'warning'))
      }
    });
  };

  return { questions, status, error, qty, errorMessage, deleteQuestion, filters };
};
