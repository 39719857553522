import { TableContainer, TablePagination } from "@mui/material";
import Table from "@mui/material/Table";
import Box from "../../../components/Box";
import TableRow from "@mui/material/TableRow";
import DataTableHeadCell from "../components/DataTableHeadCell";
import TableBody from "@mui/material/TableBody";
import DataTableBodyCell from "../components/DataTableBodyCell";

const PaginationTable = ({ data, columns, renderRows, getRows }) => {
  const { count, page } = data;
  let rowsPerPage = localStorage.getItem('itemsPerPage') ? parseInt(localStorage.getItem('itemsPerPage'), 10) : 100;

  const handleChangeRowsPerPage = (event) => {
    localStorage.setItem('itemsPerPage', parseInt(event.target.value, 10))
    getRows(rowsPerPage, page +1);
  };

  const handlePageChange = (event, newPage) => {
    getRows(rowsPerPage, newPage +1);
  }

  return (
    <TableContainer>
      <Table>
        <Box component="thead">
          <TableRow>
            {columns && columns.length > 0 && columns.map((column, index) => (
              <DataTableHeadCell
                sorted={false}
                key={ index }
                width={ column.width ? column.width : "auto" }
                align={ column.align ? column.align : "left" }
              >
                { column.header }
              </DataTableHeadCell>
            )) }
          </TableRow>
        </Box>
        <TableBody>
          {renderRows && renderRows.length > 0 && (
            renderRows.map((row, index )=> (
              <TableRow key={index}>
                {columns && columns.length > 0 && columns.map((column, key) => (
                  <DataTableBodyCell
                    key={key}
                    noBorder={ true }
                    align={ column.align ? column.align : "left" }
                  >
                    {row[column.accessor]}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            ))
          ) }
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={count}
        page={page - 1}
        showFirstButton={true}
        rowsPerPage={rowsPerPage}
        onPageChange={handlePageChange}
        rowsPerPageOptions={[25, 50, 100, 200]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default PaginationTable;