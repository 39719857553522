import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const COLLECTION_NAME = "questions";

export const loadQuestions = createAsyncThunk(
  "@@questions/list",
  (filter = null, {
    extra: { client }
  }) => {
    return client.get(`${ COLLECTION_NAME }${ filter ? `?filter=${ JSON.stringify(filter) }` : "" }`);
  }
);

export const getQuestionById = createAsyncThunk(
  "@@questions/get",
  (id, {
    extra: { client }
  }) => {
    return client.get(`${ COLLECTION_NAME }/${ id }`);
  }
);

export const createQuestions = createAsyncThunk(
  "@@questions/create",
  (data, {
    extra: { client }
  }) => {
    return client.post(COLLECTION_NAME, data);
  }
);

export const updateQuestions = createAsyncThunk(
  "@@questions/update",
  ({ id, data }, {
    extra: { client }
  }) => {
    return client.put(`${ COLLECTION_NAME }/${ id }`, data);
  }
);

export const deleteQuestions = createAsyncThunk(
  "@@questions/delete",
  (id, {
    extra: { client }
  }) => {
    return client.delete(`${ COLLECTION_NAME }/${ id }`);
  }
);

export const filters  = {
  limit: 100,
  page: 1,
  search: null,
  sort: null
}

const initialState = {
  status: "idle",
  error: null,
  errorMessage: null,
  list: [],
  one: null,
  filters
};

const questionSlice = createSlice({
  name: "@@questions",
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadQuestions.pending, (state) => {
        state.status = "loading";
        state.error = null;
        state.list = [];
      })
      .addCase(loadQuestions.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload || action.meta.error;
      })
      .addCase(loadQuestions.fulfilled, (state, action) => {
        state.status = "received";
        state.list = action.payload.data;
      })
      .addCase(getQuestionById.pending, (state) => {
        state.status = "loading";
        state.error = null;
        state.one = null;
      })
      .addCase(getQuestionById.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload || action.meta.error;
        state.one = null;
      })
      .addCase(getQuestionById.fulfilled, (state, action) => {
        state.status = "received";
        state.one = action.payload.data;
      })
      .addCase(createQuestions.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createQuestions.rejected, (state, action) => {
        const response = JSON.parse(action.error.message);

        state.status = "rejected";
        state.error = response.error;
        state.errorMessage = response.message;
      })
      .addCase(createQuestions.fulfilled, (state) => {
        state.status = "received";
      })
      .addCase(updateQuestions.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateQuestions.rejected, (state, action) => {
        const response = JSON.parse(action.error.message);

        state.status = "rejected";
        state.error = response.error;
        state.errorMessage = response.message;
      })
      .addCase(updateQuestions.fulfilled, (state) => {
        state.status = "received";
      })
      .addCase(deleteQuestions.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteQuestions.rejected, (state, action) => {
        const response = JSON.parse(action.error.message);

        state.status = "rejected";
        state.error = response.error;
        state.errorMessage = response.message;
      })
      .addCase(deleteQuestions.fulfilled, (state) => {
        state.status = "received";
      })
  }
});

export const questionReducer = questionSlice.reducer;

// Actions
export const { setFilters } = questionSlice.actions;

// Selectors
export const selectAllQuestions = (state) => state.question.list;
export const selectQuestion = (state) => state.question.one;

export const selectQuestionsInfo = (state) => ({
  status: state.question.status,
  error: state.question.error,
  qty: state.question.list.length,
  errorMessage: state.question.errorMessage,
  filters: state.question.filters
});