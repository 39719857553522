import Loader from "../../../../components/Loader";
import DataTable from "../../../../ui/Tables/DataTable";
import { columns } from "./columns";
import { rows } from "./rows";
import { useGames } from "../../../../hooks/games/useGames";

export const GamesDataTable = () => {
  const { games, error, status, deleteGame } = useGames();

  return (
    <>
      {error && <h2>Can't fetch data</h2>}
      {status === 'loading' && <Loader />}

      {status === 'received' && (
        <DataTable
          table={{ columns, rows: rows(games, deleteGame) }}
          isSorted={false}
          entriesPerPage={{ defaultValue: 25, entries: [10, 25, 50, 100] }}
          showTotalEntries={true}
          noEndBorder
        />
      )}
    </>
  )
}
