import Box from "../../components/Box";
import Footer from "../../ui/Footer";
import DashboardLayout from "../../ui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../ui/Navbars/DashboardNavbar";
import { Card, Grid } from "@mui/material";
import Typography from "../../components/Typography";
import Button from "../../components/Button";
import Icon from "@mui/material/Icon";
import { Link } from "react-router-dom";
import { GamesDataTable } from "./components/tabel/GamesDataTable";
import Input from "../../components/Input";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loadGames } from "../../slices/games";
import { setFilters, filters } from "../../slices/questions";


const Games = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState(null);

  useEffect(() => {
    if (search) {
      dispatch(loadGames(search))
    } else {
      dispatch(setFilters(filters))
    }
  }, [dispatch, search]);

  return (
    <DashboardLayout>
      <DashboardNavbar search={<Input label="Chercher" onChange={({ target }) => setSearch({ search: target.value })} />}/>
      <Box pt={ 6 } pb={ 3 }>
        <Grid container spacing={ 6 }>
          <Grid item xs={ 12 }>
            <Card>
              <Box
                mx={ 2 }
                mt={ -3 }
                py={ 3 }
                px={ 2 }
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" color="white">
                  Tableau jeux
                </Typography>
                <Button
                  component={ Link }
                  to="/games/create"
                  variant="gradient"
                  color="dark"
                >
                  <Icon sx={ { fontWeight: "bold" } }>add</Icon>
                  &nbsp;Création
                </Button>
              </Box>
              <Box pt={ 3 }>
                <GamesDataTable />
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </DashboardLayout>
  );
};

export default Games;
