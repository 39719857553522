import Loader from "../../../../components/Loader";
import { columns } from "./columns";
import { rows } from "./rows";
import Box from "../../../../components/Box";
import Typography from "../../../../components/Typography";
import Button from "../../../../components/Button";
import { Link } from "react-router-dom";
import Icon from "@mui/material/Icon";
import { Card, Grid } from "@mui/material";
import { useQuestions } from "../../../../hooks/questions/useQuestions";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { loadQuestions } from "../../../../slices/questions";
import PaginationTable from "../../../../ui/Tables/PaginationTable";

export const QuestionsDataTable = ({ game }) => {
  const dispatch = useDispatch();
  const { questions, error, status, deleteQuestion, filters } = useQuestions();

  const getQuestions = useCallback((limit, page) => {
    dispatch(loadQuestions({ ...filters, translationId: game.translations.id, limit, page  }));
  }, []);

  return (
    <>
      { error && <h2>Can't fetch data</h2> }
      { status === "loading" && <Loader /> }

      { status === "received" && (
        <Box pt={ 6 } pb={ 3 }>
          <Grid container spacing={ 6 }>
            <Grid item xs={ 12 }>
              <Card>
                <Box
                  mx={ 2 }
                  mt={ -3 }
                  py={ 3 }
                  px={ 2 }
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h6" color="white">
                    Questions
                  </Typography>
                  <Button
                    component={ Link }
                    to={ `/games/${game.id}/questions/create` }
                    variant="gradient"
                    color="dark"
                  >
                    <Icon sx={ { fontWeight: "bold" } }>add</Icon>
                    &nbsp;Création
                  </Button>
                </Box>
                <Box pt={ 3 }>
                  <PaginationTable
                    data={questions}
                    columns={columns}
                    renderRows={ rows(game.id, questions.items, deleteQuestion)}
                    getRows={getQuestions}
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      ) }
    </>
  );
};
