import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import Box from "../../../../components/Box";
import Typography from "../../../../components/Typography";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import { MenuItem, Rating } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import Risks from "../answers/Risks";
import { categories } from "../сategories";
import Practice from "../answers/Practice";
import CaseStudies from "../answers/CaseStudies";
import QCM from "../answers/QCM";
import { useParams } from "react-router-dom";
import { useCallback } from "react";
import { useLang } from "../../../../hooks/useLang";
import { useSelector } from "react-redux";
import { selectGame } from "../../../../slices/games";
import { styled } from "@mui/material/styles";
import { Grade, GradeOutlined } from "@mui/icons-material";

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

const QuestionsEditForm = ({ question, updateQuestion }) => {
  const game = useSelector(selectGame);
  const { questionId } = useParams();
  const { language } = useLang();

  const formik = useFormik({
    initialValues: {
      title: question && questionId ? question.title : "",
      category: question && questionId ? question.category : "",
      rating: question && questionId ? question.rating : 1,
      text: question && questionId ? question.text : "",
      answers: question && questionId ? question.answers : [],
      rightAnswerComment: question && questionId ? question.rightAnswerComment : "",
      wrongAnswerComment: question && questionId ? question.wrongAnswerComment : "",
      translation: game.translations.id,
      submit: null
    },
    validationSchema: Yup.object({
      title: Yup.string().required('required'),
      category: Yup.string().required('required'),
      rating: Yup.string().min(1).max(3).required('required'),
      text: Yup.string().required('required'),
      rightAnswerComment: Yup.string().required('required'),
      wrongAnswerComment: Yup.string().required('required'),
    }),
    onSubmit: async (values) => {
      const answers = values.answers.filter(answer => answer.title !== "");

      updateQuestion({ ...values, answers });
    }
  });

  const clearAnswers = useCallback(({ props: { value } }) => {
    if (id && question && question.category === value) {
      formik.setFieldValue("answers", question.answers);
    } else {
      formik.setFieldValue("answers", []);
    }
  }, [formik]);

  const getAnswersType = () => {
    switch (formik.values.category) {
      case 1:
        return <Risks
          values={ formik.values.answers }
          setFieldValue={ (name, value) => formik.setFieldValue(name, value) }
        />;
      case 2:
        return <Practice
          values={ formik.values.answers }
          setFieldValue={ (name, value) => formik.setFieldValue(name, value) }
        />;
      case 3:
        return <CaseStudies
          values={ formik.values.answers }
          setFieldValue={ (name, value) => formik.setFieldValue(name, value) }
        />;
      case 4:
        return <QCM
          values={ formik.values.answers }
          setFieldValue={ (name, value) => formik.setFieldValue(name, value) }
        />;
    }
  };

  const customIcons = {
    1: {
      icon: <Grade color="info" />,
    },
    2: {
      icon: <GradeOutlined color="secondary" />,
    },
    3: {
      icon: <Grade color="error" />,
    },
  };

  function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
  }

  return (
    <Card id="delete-account">
      <form onSubmit={ formik.handleSubmit }>
        <Box pt={ 2 } px={ 2 } display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" fontWeight="medium">
            Formulaire de questions
          </Typography>
          <img
            src={ require(`../../../../assets/images/icons/flags/${ language }.png`) }
            width="30"
            height="20"
            alt="" />
          <StyledRating
            name="rating"
            defaultValue={1}
            min={1}
            max={3}
            highlightSelectedOnly
            IconContainerComponent={IconContainer}
            onChange={ (e, value) => formik.setFieldValue("rating", value) }
            value={ formik.values.rating }
          />
        </Box>
        <Box pt={ 4 } pb={ 3 } px={ 3 }>
          <Grid container spacing={ 3 }>
            <Grid item xs={ 12 } md={ 4 }>
              <Input
                type="text"
                name="title"
                label="Titre"
                fullWidth
                helperText={ formik.touched.title && formik.errors.title }
                error={ Boolean(formik.touched.title && formik.errors.title) }
                onBlur={ formik.handleBlur }
                onChange={ formik.handleChange }
                value={ formik.values.title }
              />
            </Grid>
            <Grid item xs={ 12 } md={ 4 }>
              <Input
                SelectProps={ {
                  style: {
                    padding: 10,
                    paddingBottom: 15
                  }
                } }
                select
                size="normal"
                name="category"
                label="Catégorie"
                fullWidth
                error={ Boolean(formik.touched.category && formik.errors.category) }
                onBlur={ formik.handleBlur }
                onChange={ (e, value) => {
                  formik.handleChange(e);
                  clearAnswers(value);
                } }
                value={ formik.values.category }
              >
                { categories.map((item, index) => <MenuItem key={ index }
                                                            value={ item.value }>{ item.name }</MenuItem>) }
              </Input>
            </Grid>
            <Grid item xs={ 12 } md={ 12 }>
              <Input
                type="text"
                name="text"
                label="Texte"
                multiline
                rows={ 4 }
                fullWidth
                helperText={ formik.touched.text && formik.errors.text }
                error={ Boolean(formik.touched.text && formik.errors.text) }
                onBlur={ formik.handleBlur }
                onChange={ formik.handleChange }
                value={ formik.values.text }
              />
            </Grid>
            <Grid item xs={ 12 } md={ 12 }>
              <Typography>Réponses:</Typography>
            </Grid>
            <Grid item xs={ 12 } md={ 12 }>
              { getAnswersType() }
            </Grid>
            <Grid item xs={ 12 } md={ 12 }>
              <Input
                type="text"
                name="rightAnswerComment"
                label="Commentaire réponse correcte"
                multiline
                rows={ 4 }
                fullWidth
                helperText={ formik.touched.rightAnswerComment && formik.errors.rightAnswerComment }
                error={ Boolean(formik.touched.rightAnswerComment && formik.errors.rightAnswerComment) }
                onBlur={ formik.handleBlur }
                onChange={ formik.handleChange }
                value={ formik.values.rightAnswerComment }
              />
            </Grid>
            <Grid item xs={ 12 } md={ 12 }>
              <Input
                type="text"
                name="wrongAnswerComment"
                label="Commentaire mauvaise réponse"
                multiline
                rows={ 4 }
                fullWidth
                helperText={ formik.touched.wrongAnswerComment && formik.errors.wrongAnswerComment }
                error={ Boolean(formik.touched.wrongAnswerComment && formik.errors.wrongAnswerComment) }
                onBlur={ formik.handleBlur }
                onChange={ formik.handleChange }
                value={ formik.values.wrongAnswerComment }
              />
            </Grid>
          </Grid>
        </Box>
        <Box pb={ 2 } px={ 2 } display="flex" justifyContent="space-between" alignItems="center">
          <Button variant="gradient" color="warning" onClick={ () => formik.resetForm() }>
            <Icon sx={ { fontWeight: "bold" } }>replay</Icon>
            &nbsp;Réinitialiser
          </Button>
          <Button type="na" variant="gradient" color="info" disabled={ formik.isSubmitting }>
            <Icon sx={ { fontWeight: "bold" } }>save</Icon>
            &nbsp;Enregistrer
          </Button>
        </Box>
      </form>
    </Card>
  );
};

export default QuestionsEditForm;
